import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";

export function listarCampanhas() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarCampanhas, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				store.commit("cadastro-banner/SET_CARREGADO", true);
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function tituloCampanhas() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().tituloCampanhas, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				store.commit("cadastro-banner/SET_CARREGADO", true);
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarCampanhasFiltro(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().listarCampanhasFiltro, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				store.commit("cadastro-banner/SET_CARREGADO", true);
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function cadastrarPremios(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().cadastroPremio, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				if (erro.response.status === 401) {
					store.commit("cadastro-banner/SET_CARREGADO", true);
					tokenExpirado(erro, true);
					return;
				}
				store.commit("SET_MODAL", {
					ativado: true,
					mensagem: erro.response.data.mensagem,
					tipo: "erro"
				});
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function cadastrarCampanha(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().cadastrarCampanha, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				if (erro.response.status == 401) {
					tokenExpirado(erro, true);
				}

				if (erro.response.data.errors.ArquivoInvalido) {
					store.commit("SET_MODAL", {
						ativado: true,
						mensagem: erro.response.data.errors.ArquivoInvalido[0],
						tipo: "erro"
					});
				}

				if (erro.response.data.errors.CampoInvalido) {
					store.commit("SET_MODAL", {
						ativado: true,
						mensagem: erro.response.data.errors.CampoInvalido[0],
						tipo: "erro"
					});
				}
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function atualizaPremio(premio) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().atualizaPremio, premio, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function atualizarCampanha(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().atualizarCampanha, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				if (erro.response.status == 401) {
					tokenExpirado(erro, true);
				}

				if (erro.response.data.errors.ArquivoInvalido) {
					store.commit("SET_MODAL", {
						ativado: true,
						mensagem: erro.response.data.errors.ArquivoInvalido[0],
						tipo: "erro"
					});
				}

				if (erro.response.data.errors.CampoInvalido) {
					store.commit("SET_MODAL", {
						ativado: true,
						mensagem: erro.response.data.errors.CampoInvalido[0],
						tipo: "erro"
					});
				}
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function buscaPremioPorCampanha(idCampanha) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().buscaPremiosPorCampanha + `/${idCampanha}?pagina=1&quantidadePorPagina=10`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function getPremioPorId(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().getPremioPorId + `/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listaPremios() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listaPremios + `?pagina=1&quantidadePorPagina=10`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function detalhesCampanha(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().detalhesCampanha + id, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function editarCampanha(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().editarCampanha, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}


export function inativarCampanha(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().inativaCampanha, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				resolve(erro.response.data);
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function excluirPosicaoPremio(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().excluirPremioInativaPremio, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				resolve(erro.response.data);
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}