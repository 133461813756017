import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";
//

export function enviarPlanilhaCampanhas(data) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().adicionarPlanilhaCampanhas, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        reject(erro.response.data.erros);
        store.commit("SET_MODAL", {
          ativado: true,
          tipo: "erro",
          mensagem: erro.response.data.message,
        });
        setTimeout(() => {
          store.commit("SET_MODAL", {
            ativado: false,
            mensagem: "",
            tipo: "",
          });
        }, 3000);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function listarCampanhas(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(`${_rotasAPI().listarCampanhas}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("promocoes/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}


export function comboCampanhas() {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().listarComboCampanhas}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("promocoes/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function listarCampanhasApuracao(data) {
  return new Promise(resolve => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(`${_rotasAPI().listarCampanhasApuracao}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(resp => {
        resolve(resp);
      })
      .catch(erro => {
        store.commit("promocoes/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}